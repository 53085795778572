<template>
    <div class="app-container">
        <div class="filter-container">
            <el-date-picker v-model="starttime" type="datetime" format="YYYY-MM-DD HH:mm:ss" placeholder="Start time" />
            <el-date-picker v-model="endtime" type="datetime" format="YYYY-MM-DD HH:mm:ss" placeholder="End time" />
            <el-input v-model="listQuery.title" :placeholder="$t('server_log.logcontent')" style="width: 200px;"
                class="filter-item" />
            <el-input v-model="listQuery.label" :placeholder="`标签或账号`" style="width: 200px;" class="filter-item" />
            <el-button v-waves class="filter-item" type="primary" icon="el-icon-search" @click="getLogList">
                {{ $t("table.search") }}
            </el-button>
            <el-button v-waves :loading="downloadLoading" class="filter-item" type="primary" icon="el-icon-download"
                @click="handleDownload">
                {{ $t("table.export") }}
            </el-button>
        </div>

        <el-table :key="tableKey" v-loading="listLoading" :data="list" border fit highlight-current-row
            style="width: 100%;">
            <el-table-column :label="$t('table.id')" prop="id" align="center" width="80px">
                <template #default="{ row }">
                    <span>{{ row.Index }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="`账号`" width="180px" align="center">
                <template #default="{ row }">
                    <span>{{ row.AccountId}}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('table.date')" width="180px" align="center">
                <template #default="{ row }">
                    <span>{{ new Date(Number(row.Time)).toLocaleString() }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('table.title')" min-width="150px">
                <template #default="{ row }">
                    <span class="link-type" @click="handleSingleLogInfo(row)" v-html="GetShotMsg(row)">
                    </span>
                </template>
            </el-table-column>
            <el-table-column :label="`次数`" width="80px" align="center">
                <template #default="{ row }">
                    <span>{{ row.Count }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('server_log.label')" width="180px" align="center">
                <template #default="{ row }">
                    <span>{{ row.Label }}</span>
                </template>
            </el-table-column>
            <el-table-column :label="$t('table.actions')" align="center" width="80px" class-name="fixed-width">
                <template #default="{ row }">
                    <el-button v-if="row.Level === 5" size="mini" type="danger" @click="handleIgoneLog(row)">
                        {{ $t("table.delete") }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination :total="total" v-show="total > 0" v-model:page="listQuery.page" v-model:limit="listQuery.limit"
            @current-change="handleCurrentChange" :current-page="listQuery.page" layout="total, prev, pager, next, jumper"
            :page-size="listQuery.limit" />

        <el-dialog v-model="dialogFormVisible" :title="'LogInfo'">
            <el-form ref="dataForm" :model="tempLogRecord" label-position="left" label-width="100px" style="width: 100%">
                <el-form-item :label="'LogMsg'" prop="title">
                    <span v-html="tempLogRecord.FullMsg" />
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
  
<script lang="ts">
import { GameManage_GMSearchClientSuggest } from '@/apis/game_manage';
import { ServerManage_GMIgnoreErrorLog } from '@/apis/server_manage';
import { formatJson } from "@/utils";
import { exportJson2Excel } from "@/utils/excel";
import { TodayEnd, TodayStart } from "@/utils/timer";
import { ElForm, ElMessage, ElMessageBox } from 'element-plus';
import { defineComponent, nextTick, onMounted, reactive, ref, toRefs } from 'vue';
import { useI18n } from 'vue-i18n';

export default defineComponent({

    setup() {
        const { t } = useI18n()
        const dataForm = ref<typeof ElForm>()
        const dataMap = reactive({
            time: Date,
            tableKey: 0,
            list: [] as IClientSuggest[],
            total: 1,
            listLoading: true,
            starttime: TodayStart(),
            endtime: TodayEnd(),

            listQuery: {
                page: 1,
                limit: 20,
                logLevel: "Error",
                title: "",
                label: "",
                process: "",
                sort: "+id"
            },
            processOptions: [] as IClientSuggest[],
            labelOptions: [""],
            dialogFormVisible: false,
            downloadLoading: false,
            tempLogRecord: { FullMsg: "" },
        })


        const handleCurrentChange = (page: number) => {
            dataMap.listQuery.page = page;
            getLogList();
        };
        const handleSizeChange = (val: any) => {
            // dataMap.getList(null, null, val)
        };
        const getLogList = async () => {
            dataMap.listLoading = true;
            dataMap.list = [];
            let msg = {} as IC2G_GMSearchClientSuggest;
            msg.StartTime = Math.ceil(dataMap.starttime.getTime() / 1000);
            msg.EndTime = Math.ceil(dataMap.endtime.getTime() / 1000);
            msg.PageCount = dataMap.listQuery.limit;
            msg.PageIndex = dataMap.listQuery.page;
            if (dataMap.listQuery.label !== "") {
                msg.Label = dataMap.listQuery.label;
            }
            if (dataMap.listQuery.title !== "") {
                msg.Title = dataMap.listQuery.title;
            }
            let cbmsg = await GameManage_GMSearchClientSuggest(msg);
            if (cbmsg?.Error !== 0) {
                ElMessage.error(`GetLogFail: ${cbmsg?.Message}`);
                return;
            }
            if (cbmsg.SearchResult) {
                for (let k of cbmsg.SearchResult) {
                    let record = JSON.parse(k) as IClientSuggest;
                    record.Id = record._id;
                    if (record.Label === "") {
                        record.Label = "{}";
                    }
                    let labelInfo = JSON.parse(record.Label);
                    record.AccountId = labelInfo.AccountId || "";
                    record.Label = labelInfo.Label || "";
                    record.Index = dataMap.list.length + 1 + (dataMap.listQuery.page - 1) * dataMap.listQuery.limit;
                    dataMap.list.push(record);
                }
            }
            dataMap.total = cbmsg.SearchCount;
            dataMap.listLoading = false;
        }

        const GetShotMsg = (row: IClientSuggest) => {
            if (row == null || row.Msg == null) { return "" }
            let arr = row.Msg.split("\r\n");
            if (arr.length >= 3) {
                let i = 0;
                let r = "";
                while (i < 3) {
                    r += arr[i] + "<br>";
                    i++;
                }
                return r;
            } else {
                return row.Msg;
            }
        }
        const GetFullMsg = (row: IClientSuggest) => {
            if (row == null || row.Msg == null) { return "" }
            let arr = row.Msg.split("\r\n");
            let i = 0;
            let r = "";
            while (i < arr.length) {
                r += arr[i] + "<br>";
                i++;
            }
            return r;
        }

        const handleSingleLogInfo = (row: IClientSuggest) => {
            dataMap.tempLogRecord.FullMsg = GetFullMsg(row);
            dataMap.dialogFormVisible = true;
            nextTick(() => {
                (dataForm.value as any).clearValidate()
            });
        }
        const handleIgoneLog = (row: IClientSuggest) => {
            ElMessageBox.confirm(
                `Confirm to Delete ErrorLog<${row.Id}> ?`,
                "Delete ErrorLog",
                {
                    confirmButtonText: "Confirm",
                    cancelButtonText: "Cancel",
                    type: "warning"
                }
            )
                .then(async () => {
                    let msg = {} as IC2G_GMIgnoreErrorLog;
                    msg.LogId = row.Id;
                    msg.LogTime = Math.ceil(parseInt(row.Time) / 1000);
                    msg.LogProcess = row.Process;
                    let cbmsg = await ServerManage_GMIgnoreErrorLog(msg);
                    if (cbmsg?.Error == 0) {
                        ElMessage.success(`Log<${row.Id}> Delete ErrorLog`)
                        row.Level = 0;
                    } else {
                        ElMessage.error(`Log<${row.Id}> Delete ErrorLog`)
                    }
                })
                .catch(err => {
                    console.error(err);
                });
        }

        const handleDownload = () => {
            dataMap.downloadLoading = true;
            const tHeader = ["Id", "Time", "Level", "Label", "Msg"];
            const filterVal = ["Id", "Time", "Level", "Label", "Msg"];
            const data = formatJson(filterVal, dataMap.list);
            exportJson2Excel(tHeader, data, "GameError");
            dataMap.downloadLoading = false;
        }

        onMounted(() => {
            getLogList()
        })

        return {
            t, ...toRefs(dataMap),
            dataForm,
            getLogList,
            GetShotMsg,
            handleCurrentChange,
            handleSizeChange,
            handleSingleLogInfo,
            handleIgoneLog,
            handleDownload
        }
    }
})

</script>
  